import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { Box, Button, FormLabel, Stack } from '@mui/material';
import { useStore } from 'context/StoreContext';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { OPTIONS_KEYS } from 'services/constants';
import * as yup from 'yup';
import DateRangePicker from 'components/DateRangePicker';
import { SideBarItemID } from 'components/DateRangePicker/constants';
import NewAutocompleteField from 'components/Form/NewAutocompleteField';
import { tableFiltersInitialValues, useTableContext } from '../TableTabs/ReportTable/reportTable.context';
import ComboButton from './components/ComboButton';
import { FilterPreferencesProps, FormData } from './types';

const validationSchema = yup.object().shape({
  dateTimeStart: yup.string().nullable().required('Required field!'),
  dateTimeEnd: yup.string().nullable().required('Required field!'),
  campaignIds: yup.array().min(1, 'Required field!').nullable().required('Required field!'),
  lineIds: yup.array().min(1, 'Required field!').nullable().required('Required field!'),
  abmAudienceIds: yup.array().min(1, 'Required field!').nullable().required('Required field!'),
});

export const initialValues: FormData = {
  campaignIds: [],
  lineIds: [],
  abmAudienceIds: [],
  dateTimeStart: moment().subtract(30, 'day').toDate(),
  dateTimeEnd: moment().subtract(1, 'day').toDate(),
};

const Filter: FC<FilterPreferencesProps> = ({
  isLoading,
  downloadDisabled,
  onCSVDownload,
  onPdfDownload,
  onFilterApply,
  onFilterReset,
}) => {
  const { t } = useTranslation();

  const { selectedAccounts } = useStore();

  const { setFilters } = useTableContext();

  const [optionsQueryString, setOptionsQueryString] = useState<{ lines: string | null }>({
    lines: null,
  });

  const accountsQueryString = useMemo(
    () => (selectedAccounts ? selectedAccounts.map((item) => `&accountId=${item}`).join('') : ''),
    [selectedAccounts],
  );

  const handleCampaignsClose = useCallback((values: FormData) => {
    setOptionsQueryString({
      lines: values.campaignIds.length
        ? values.campaignIds.map((item: number) => `&campaignId=${item}`).join('')
        : null,
    });
  }, []);

  const handleSubmit = (values: FormData) => {
    onFilterApply(values);
    setFilters(tableFiltersInitialValues);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnChange={false}
    >
      {({ setFieldValue, values, resetForm, dirty }) => (
        <Form>
          <Box display='flex' justifyContent='space-between' alignItems='flex-start' gap={2}>
            <NewAutocompleteField
              multiple
              required
              label='Campaigns'
              name='campaignIds'
              placeholder={t('Select Campaign')}
              optionsKey={OPTIONS_KEYS.CAMPAIGNS}
              queryData={'$limit=10000' + accountsQueryString}
              onClose={() => handleCampaignsClose(values)}
              onChangeHelper={() => {
                setFieldValue('lineIds', [], false);
                setFieldValue('lineCreativeIds', [], false);
              }}
            />

            <NewAutocompleteField
              multiple
              required
              label='Line'
              name='lineIds'
              placeholder={t('Select Line')}
              disabled={!optionsQueryString.lines}
              optionsKey={OPTIONS_KEYS.LINES}
              queryData={'$limit=10000' + optionsQueryString.lines}
              queryOptions={{ enabled: Boolean(optionsQueryString.lines) }}
            />

            <NewAutocompleteField
              multiple
              required
              label='ABM Audience'
              name='abmAudienceIds'
              placeholder={t('ABM audience')}
              optionsKey={OPTIONS_KEYS.ABM_AUDIENCES}
            />

            <Box width={250}>
              <Box display='flex' alignItems='center' gap={0.5} pb='2px'>
                <FormLabel>Date range *</FormLabel>
              </Box>
              <DateRangePicker
                hiddenSideBarItems={[SideBarItemID.TODAY]}
                maxDate={moment().subtract('days', 1).toDate()}
                startDateName='dateTimeStart'
                endDateName='dateTimeEnd'
              />
            </Box>
          </Box>

          <Box display='flex' gap={2} pt={4} className='hideOnPrint'>
            <LoadingButton
              disabled={!dirty}
              loading={isLoading}
              type='submit'
              variant='contained'
              disableRipple
              sx={{ width: 124 }}
            >
              {t('Run', { ns: 'common' })}
            </LoadingButton>
            <Button
              variant='outlined'
              disableRipple
              sx={{ width: 140 }}
              onClick={() => {
                resetForm({ values: initialValues });
                onFilterReset();
              }}
            >
              {t('Reset filters', { ns: 'common' })}
            </Button>

            <Stack direction='row' gap={1} ml='auto'>
              <ComboButton disabled={downloadDisabled} onCSVDownload={onCSVDownload} onPdfDownload={onPdfDownload} />
            </Stack>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default Filter;
