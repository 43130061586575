export const getStatusColor = (status: string): 'error' | 'success' | 'warning' | 'default' | 'info' | 'primary' => {
  const isActive = ['active', 'success', 'approved', 'scheduled', 'reviewed by ops'].includes(status);

  if (isActive) {
    return 'success';
  }

  switch (status) {
    case 'inactive': {
      return 'info';
    }
    case 'under revision': {
      return 'error';
    }
    case 'paused': {
      return 'warning';
    }
    default: {
      return 'default';
    }
  }
};
