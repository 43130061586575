export const campaignStatusOptions = [
  { label: 'Active', value: 'Active' },
  { label: 'Inactive', value: 'Inactive' },
  { label: 'Paused', value: 'Paused' },
  { label: 'Scheduled', value: 'Scheduled' },
];

export const geoTypeOptions = [
  { label: 'Geo', value: 'geo' },
  { label: 'Region', value: 'region' },
  { label: 'Zipcode', value: 'zipcode' },
];
